<template>
  <v-card class="pb-4">
    <v-card-title class="grey--text text-subtitle-1">
      Setting Orari e Ritiri
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-dialog
            ref="dialog"
            v-model="modal_time_apertura"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                :value="time_apertura"
                label="Orario Apertura"
                prepend-inner-icon="mdi-clock"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'inserire una data']"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modal_time_apertura"
              v-model="time_apertura"
              full-width
              format="24hr"
              @click:hour="closePickerApertura"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_time_apertura = false">
                Cancel
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            ref="dialog"
            v-model="modal_time_chiusura"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                :value="time_chiusura"
                label="Orario Chiusura"
                prepend-inner-icon="mdi-clock"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modal_time_chiusura"
              v-model="time_chiusura"
              full-width
              format="24hr"
              :allowed-hours="(v) => v >= this.time_apertura.split(':')[0]"
              @click:hour="closePickerChiusura"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_time_chiusura = false">
                Cancel
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            v-model="time_prep"
            type="number"
            prepend-inner-icon="mdi-timer-sand-full"
            label="Tempo Medio Preparazione CC"
            suffix="Min"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      modal_time_apertura: false,
      time_apertura: this.$store.getters.get_soglie_orari.apertura + ":00",
      modal_time_chiusura: false,
      time_chiusura: this.$store.getters.get_soglie_orari.chiusura + ":00",
      time_prep: this.$store.getters.get_soglie_orari.t_prep_cc,
    };
  },
  created() {
    this.debounceTPrep = _.debounce(this.set_t_prep, 2000);
  },
  computed: {
    dateFormatter() {
      moment.locale("it");
      return this.time ? moment(this.date).format("dddd D MMMM") : "";
    },
    orari() {
      return this.$store.getters.get_soglie_orari;
    },
  },
  watch: {
    time_prep: function () {
      this.debounceTPrep();
    },
  },
  methods: {
    closePickerApertura: function (v) {
      this.$store.dispatch("change_orario_apertura", v);
      this.time_apertura = v + ":00";
      this.modal_time_apertura = false;
    },
    closePickerChiusura: function (v) {
      this.$store.dispatch("change_orario_chiusura", v);
      this.time_chiusura = v + ":00";

      this.modal_time_chiusura = false;
    },
    set_t_prep() {
      this.$store.dispatch("change_tempo_prep_cc", this.time_prep);
    },
  },
};
</script>