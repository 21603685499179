<template>
  <v-row>
    <v-col cols="12" md="6">
      <SettingConsegne />
    </v-col>
    <v-col cols="12" md="6">
      <SettingTrasporto />
    </v-col>
    <v-col cols="12" md="6">
      <SettingOrari />
    </v-col>
    <v-col cols="12" md="6">
      <SettingStampante />
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title class="grey--text text-subtitle-1">
          Cambia Password
        </v-card-title>
        <v-card-text>
          <v-dialog v-model="dialog_pwd" width="400" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" outlined class="mt-2" v-on="on">
                Cambia
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">
                Cambia Password
              </v-card-title>
              <v-card-text>
                <br />
                <v-row justify="center">
                  <v-col cols="12" md="10">
                    <v-form v-model="valid" ref="form">
                      <v-text-field
                        label="Vecchia Password"
                        outlined
                        v-model="pwd_old"
                        prepend-inner-icon="mdi-key"
                        :append-icon="show_old ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_old = !show_old"
                        :type="show_old ? 'text' : 'password'"
                        :rules="old_Rules"
                        @focus="valid_pwd = true"
                      />
                      <br />
                      <v-text-field
                        label="Nuova Password"
                        outlined
                        v-model="pwd_new"
                        prepend-inner-icon="mdi-key"
                        :rules="new_Rules"
                        :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_new = !show_new"
                        :type="show_new ? 'text' : 'password'"
                      />
                      <v-text-field
                        label="Ripeti Password"
                        outlined
                        v-model="pwd_reap"
                        prepend-inner-icon="mdi-key"
                        :rules="reap_Rules"
                        :append-icon="show_reap ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show_reap = !show_reap"
                        :type="show_reap ? 'text' : 'password'"
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog_pwd = false" color="primary" text>
                  Annulla
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  @click="verify()"
                  :loading="loading"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SettingTrasporto from "./setting_prezzi_trasporto.vue";
import SettingConsegne from "./setting_consegne.vue";
import SettingOrari from "./setting_orari_ritiri.vue";
import SettingStampante from "./setting_stampanti.vue"
import axios from 'axios'
export default {
  components: {
    SettingTrasporto,
    SettingConsegne,
    SettingOrari,
    SettingStampante
  },
  data() {
    return {
      dialog_pwd: false,
      dialog_confirm: false,
      pwd_old: "",
      pwd_new: "",
      pwd_reap: "",
      valid: true,
      valid_pwd: true,
      old_Rules: [
        (v) => !!v || "Vecchia Password richiesta",
        () => this.valid_pwd == true || "Password Errata",
      ],
      new_Rules: [
        (v) => !!v || "Nuova Password richiesta",
        (v) => v.length > 7 || "Almeno 8 caratteri",
      ],
      reap_Rules: [(v) => v == this.pwd_new || "Deve essere uguale"],
      show_old: false,
      show_new: false,
      show_reap: false,
      loading: false,
    };
  },
  methods: {
    verify() {
      this.loading = true;
      let iva = this.$store.getters.get_utente.iva
      this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        return;
      }
      var url = process.env.VUE_APP_API_ROOT + "/token";
      var auth = {
        username: iva,
        password: this.pwd_old,
      };
      axios
        .get(url, {
          auth: auth,
        })
        .then((res) => {
          var url = process.env.VUE_APP_API_ROOT + "/set_new_password";
          var auth = {
            username: res.data.token,
            password: "",
          };
          var json = {
            iva: iva,
            new_pwd: this.pwd_new,
          };
          axios
            .post(url, json, {
              auth: auth,
            })
            .then(() => {
              this.loading = false;
              this.dialog_pwd = false;
              this.dialog_confirm = true;
              this.$refs.form.reset();
            });
        })
        .catch(() => {
          this.loading = false;
          this.valid_pwd = false;
          this.$refs.form.validate();
        });
    },
  },
};
</script>

