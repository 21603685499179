<template>
  <v-card class="pb-4">
    <v-card-title class="grey--text text-subtitle-1">
      Setting Stampante
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            hide-details
            prepend-inner-icon="mdi-ip-network"
            label="Indirizzo IP"
            v-model="ip_stampante"
          >
            <template v-slot:append>
              <v-progress-circular
                indeterminate
                color="green"
                size="20"
                width="2"
                v-if="loading_ip_stampante"
              ></v-progress-circular>
              <v-icon color="green" v-if="ok_ip_stampante">mdi-check</v-icon>
              <v-icon color="red" v-if="fail_ip_stampante">mdi-error</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            hide-details
            prepend-inner-icon="mdi-lan"
            label="Porta"
            v-model="porta_stampante"
          >
            <template v-slot:append>
              <v-progress-circular
                indeterminate
                color="green"
                size="20"
                width="2"
                v-if="loading_porta_stampante"
              ></v-progress-circular>
              <v-icon color="green" v-if="ok_porta_stampante">mdi-check</v-icon>
              <v-icon color="red" v-if="fail_porta_stampante">mdi-error</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn outlined color="green" @click="stampa_etichetta_prova()"
            ><v-icon class="mr-2">mdi-printer</v-icon> Test di stampa</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      ip_stampante: this.$store.getters.get_stampante_remota.ip,
      porta_stampante: this.$store.getters.get_stampante_remota.porta,
      ok_ip_stampante: false,
      fail_ip_stampante: false,
      ok_porta_stampante: false,
      fail_porta_stampante: false,
      loading_ip_stampante: false,
      loading_porta_stampante: false,
    };
  },
  created() {
    this.debounceIp = _.debounce(this.set_ip, 2000);
    this.debouncePort = _.debounce(this.set_port, 2000);
  },
  watch: {
    ip_stampante: function () {
      this.debounceIp();
    },
    porta_stampante: function () {
      this.debouncePort();
    },
  },
  computed: {},
  methods: {
    set_ip() {
      this.$store.dispatch("change_ip_stampante", this.ip_stampante);
    },
    set_port() {
      this.$store.dispatch("change_porta_stampante", this.porta_stampante);
    },
    stampa_etichetta_prova(){
        this.$store.dispatch("stampa_etichetta_prova")
    }
  },
};
</script>