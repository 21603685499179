var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-4"},[_c('v-card-title',{staticClass:"grey--text text-subtitle-1"},[_vm._v(" Setting Orari e Ritiri ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.time_apertura,"label":"Orario Apertura","prepend-inner-icon":"mdi-clock","readonly":"","rules":[function (v) { return !!v || 'inserire una data'; }]}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal_time_apertura),callback:function ($$v) {_vm.modal_time_apertura=$$v},expression:"modal_time_apertura"}},[(_vm.modal_time_apertura)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:hour":_vm.closePickerApertura},model:{value:(_vm.time_apertura),callback:function ($$v) {_vm.time_apertura=$$v},expression:"time_apertura"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_time_apertura = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.time_chiusura,"label":"Orario Chiusura","prepend-inner-icon":"mdi-clock","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal_time_chiusura),callback:function ($$v) {_vm.modal_time_chiusura=$$v},expression:"modal_time_chiusura"}},[(_vm.modal_time_chiusura)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-hours":function (v) { return v >= this$1.time_apertura.split(':')[0]; }},on:{"click:hour":_vm.closePickerChiusura},model:{value:(_vm.time_chiusura),callback:function ($$v) {_vm.time_chiusura=$$v},expression:"time_chiusura"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal_time_chiusura = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","type":"number","prepend-inner-icon":"mdi-timer-sand-full","label":"Tempo Medio Preparazione CC","suffix":"Min"},model:{value:(_vm.time_prep),callback:function ($$v) {_vm.time_prep=$$v},expression:"time_prep"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }