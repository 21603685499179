<template>
  <v-card class="pb-4">
    <v-card-title class="grey--text text-subtitle-1">
      Setting Prezzi Trasporto
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col> Prezzi per listino </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                hide-details
                prepend-inner-icon="mdi-currency-eur"
                label="Listino 1"
                v-model="prezzo_listino_1"
                type="number"
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    color="green"
                    size="20"
                    width="2"
                    v-if="loading_listino_1"
                  ></v-progress-circular>
                  <v-icon color="green" v-if="ok_listino_1">mdi-check</v-icon>
                  <v-icon color="red" v-if="fail_listino_1">mdi-error</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                hide-details
                prepend-inner-icon="mdi-currency-eur"
                label="Listino 2"
                v-model="prezzo_listino_2"
                type="number"
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    color="green"
                    size="20"
                    width="2"
                    v-if="loading_listino_2"
                  ></v-progress-circular>
                  <v-icon color="green" v-if="ok_listino_2">mdi-check</v-icon>
                  <v-icon color="red" v-if="fail_listino_2">mdi-error</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                hide-details
                prepend-inner-icon="mdi-currency-eur"
                label="Listino 3"
                v-model="prezzo_listino_3"
                type="number"
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    color="green"
                    size="20"
                    width="2"
                    v-if="loading_listino_3"
                  ></v-progress-circular>
                  <v-icon color="green" v-if="ok_listino_3">mdi-check</v-icon>
                  <v-icon color="red" v-if="fail_listino_3">mdi-error</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="text-truncate"> Soglia trasporto gratuito </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                hide-details
                prepend-inner-icon="mdi-currency-eur"
                label="Soglia"
                v-model="soglia"
                type="number"
              >
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    color="green"
                    size="20"
                    width="2"
                    v-if="loading_soglia"
                  ></v-progress-circular>
                  <v-icon color="green" v-if="ok_soglia">mdi-check</v-icon>
                  <v-icon color="red" v-if="fail_soglia">mdi-error</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  created() {
    this.debounceListino1 = _.debounce(this.set_listino_1, 2000);
    this.debounceListino2 = _.debounce(this.set_listino_2, 2000);
    this.debounceListino3 = _.debounce(this.set_listino_3, 2000);
    this.debounceSoglia = _.debounce(this.set_soglia, 2000);
  },
  data() {
    return {
      prezzo_listino_1: this.$store.getters.get_trasporto.prezzo_listino_1,
      prezzo_listino_2: this.$store.getters.get_trasporto.prezzo_listino_2,
      prezzo_listino_3: this.$store.getters.get_trasporto.prezzo_listino_3,
      soglia: this.$store.getters.get_trasporto.soglia,
      loading_listino_1: false,
      ok_listino_1: false,
      fail_listino_1: false,
      loading_listino_2: false,
      ok_listino_2: false,
      fail_listino_2: false,
      loading_listino_3: false,
      ok_listino_3: false,
      fail_listino_3: false,
      loading_soglia: false,
      ok_soglia: false,
      fail_soglia: false,
    };
  },
  watch: {
    prezzo_listino_1: function () {
      this.debounceListino1();
    },
    prezzo_listino_2: function () {
      this.debounceListino2();
    },
    prezzo_listino_3: function () {
      this.debounceListino3();
    },
    soglia: function () {
      this.debounceSoglia();
    },
  },
  methods: {
    set_listino_1() {
      this.loading_listino_1 = true;
      this.$store
        .dispatch("change_spesa_trasporto_1", this.prezzo_listino_1)
        .then(() => {
          this.loading_listino_1 = false;
          this.ok_listino_1 = true;
          setTimeout(() => {
            this.ok_listino_1 = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_listino_1 = false;
          this.fail_listino_1 = true;
          setTimeout(() => {
            this.fail_listino_1 = false;
          }, 2000);
        });
    },
    set_listino_2() {
      this.loading_listino_2 = true;
      this.$store
        .dispatch("change_spesa_trasporto_2", this.prezzo_listino_2)
        .then(() => {
          this.loading_listino_2 = false;
          this.ok_listino_2 = true;
          setTimeout(() => {
            this.ok_listino_2 = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_listino_2 = false;
          this.fail_listino_2 = true;
          setTimeout(() => {
            this.fail_listino_2 = false;
          }, 2000);
        });
    },
    set_listino_3() {
      this.loading_listino3 = true;
      this.$store
        .dispatch("change_spesa_trasporto_3", this.prezzo_listino_3)
        .then(() => {
          this.loading_listino_3 = false;
          this.ok_listino_3 = true;
          setTimeout(() => {
            this.ok_listino_3 = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_listino_3 = false;
          this.fail_listino_3 = true;
          setTimeout(() => {
            this.fail_listino_3 = false;
          }, 2000);
        });
    },
    set_soglia() {
      this.loading_soglia = true;
      this.$store
        .dispatch("change_soglia_trasporto", this.soglia)
        .then(() => {
          this.loading_soglia = false;
          this.ok_soglia = true;
          setTimeout(() => {
            this.ok_soglia = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_soglia = false;
          this.fail_soglia = true;
          setTimeout(() => {
            this.fail_soglia = false;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>