<template>
  <SettingDesktop  />
</template>

<script>
import SettingDesktop from "@/components/Setting/desktop/setting_desktop.vue"
export default {
  components: {
    SettingDesktop
  },
};
</script>