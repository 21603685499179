<template>
  <v-card class="pb-4">
    <v-card-title class="grey--text text-subtitle-1">
      Setting Consegne
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col> Tempi Consegna </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                label="Tempo di Scarico"
                v-model="tempo_scarico"
                prepend-inner-icon="mdi-timer"
                suffix="Minuti"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                label="Tempo di Cambio"
                v-model="tempo_cambio"
                prepend-inner-icon="mdi-timer"
                suffix="Minuti"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                label="Tempo di Pagamento"
                v-model="tempo_pagamento"
                prepend-inner-icon="mdi-timer"
                suffix="Minuti"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row align="center">
            <v-col>
              <span class="ml-5">Lista Zone</span>
              <v-dialog v-model="dialog_zona" width="400">
                <template v-slot:activator="{ on }">
                  <v-btn color="green" class="ml-6" icon small dark v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="grey--text text-subtitle-1">
                    Inserisci nuova zona
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      hide-details
                      outlined
                      label="Nuova Zona"
                      v-model="nuova_zona"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text color="grey" @click="dialog_zona = false"
                      >Annulla</v-btn
                    >
                    <v-btn
                      outlined
                      color="green"
                      :loading="loading_nuova_zona"
                      @click="invia_nuova_zona()"
                    >
                      Invia
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-list>
            <v-virtual-scroll
              :item-height="40"
              :items="lista_zone"
              height="220"
            >
              <template v-slot:default="{ item }">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn small icon @click="elimina_zona(item)">
                      <v-icon size="20">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  created() {
    this.debounceTempoScarico = _.debounce(this.set_tempo_scarico, 2000);
    this.debounceTempoCambio = _.debounce(this.set_tempo_cambio, 2000);
    this.debounceTempoPagamento = _.debounce(this.set_tempo_pagamento, 2000);
  },
  data() {
    return {
      nuova_zona: "",
      dialog_zona: false,
      loading_nuova_zona: false,
      loading_elimina: false,
      tempo_scarico: this.$store.getters.get_tempi.tempo_scarico_cc,
      tempo_cambio: this.$store.getters.get_tempi.tempo_cambio_cc,
      tempo_pagamento: this.$store.getters.get_tempi.tempo_pagamento,
      loading_scarico: false,
      ok_scarico: false,
      fail_scarico: false,
      loading_cambio: false,
      ok_cambio: false,
      fail_cambio: false,
      loading_pagamento: false,
      ok_pagamento: false,
      fail_pagamento: false,
    };
  },
  watch: {
    tempo_scarico: function () {
      this.debounceTempoScarico();
    },
    tempo_cambio: function () {
      this.debounceTempoCambio();
    },
    tempo_pagamento() {
      this.debounceTempoPagamento();
    },
  },
  computed: {
    lista_zone() {
      let lista = this.$store.getters.get_zone;
      return lista.sort((a, b) => a < b);
    },
  },
  methods: {
    invia_nuova_zona() {
      this.loading_nuova_zona = true;
      this.$store
        .dispatch("aggiungi_zona_consegna", this.nuova_zona)
        .catch((err) => {
          if (err == 409) {
            alert("Esiste già");
          }
        })
        .finally(() => {
          this.loading_nuova_zona = false;
          this.nuova_zona = "";
          this.dialog_zona = false;
        });
    },
    elimina_zona(zona) {
      this.loading_elimina = true;
      this.$store.dispatch("elimina_zona_consegna", zona).finally(() => {
        this.loading_elimina = false;
      });
    },
    set_tempo_scarico() {
      this.loading_scarico = true;
      this.$store
        .dispatch("change_tempo_scarico_cc", this.tempo_scarico)
        .then(() => {
          this.loading_scarico = false;
          this.ok_scarico = true;
          setTimeout(() => {
            this.ok_scarico = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_scarico = false;
          this.fail_scarico = true;
          setTimeout(() => {
            this.fail_scarico = false;
          }, 2000);
        });
    },
    set_tempo_cambio() {
      this.loading_cambio = true;
      this.$store
        .dispatch("change_tempo_cambio_cc", this.tempo_cambio)
        .then(() => {
          this.loading_cambio = false;
          this.ok_cambio = true;
          setTimeout(() => {
            this.ok_cambio = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_cambio = false;
          this.fail_cambio = true;
          setTimeout(() => {
            this.fail_cambio = false;
          }, 2000);
        });
    },
    set_tempo_pagamento() {
      this.loading_pagamento = true;
      this.$store
        .dispatch("change_tempo_pagamento", this.tempo_pagamento)
        .then(() => {
          this.loading_pagamento = false;
          this.ok_pagamento = true;
          setTimeout(() => {
            this.ok_pagamento = false;
          }, 2000);
        })
        .catch(() => {
          this.loading_pagamento = false;
          this.fail_pagamento = true;
          setTimeout(() => {
            this.fail_pagamento = false;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
</style>